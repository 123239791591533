/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'

import {
  isProd,
  isQA,
  isMaster,
  ActionUrl,
  countryCodeName,
  projectCodeName,
  CountryCode,
  PropertyCode,
  recordTypeOptions,
  corporateRecordTypes,
} from './ContactUsFormSF.utils'
import HiddenFields from './component/HiddenFields'
import './ContactUsFormSF.scss'

function ContactUsFormSF(): JSX.Element {
  const queryParams = new URLSearchParams(window.location.search)

  const [isAgreeChecked, setIsAgreeChecked] = useState<boolean>(false)
  const [isRecaptchaChecked, setIsRecaptchaChecked] = useState<boolean>(false)
  const [isCorporate, setIsCorporate] = useState<boolean>(false)
  const [isSuccessModal, setIsSuccessModal] = useState<boolean>(false)

  const recordTypesOptions = recordTypeOptions.filter((recordType) =>
    isProd
      ? recordType.env === 'prod'
      : isQA
      ? recordType.env === 'qa'
      : isMaster
      ? recordType.env === 'master'
      : recordType.env === 'dev',
  )

  const [formData, setFormData] = useState({
    recordType: recordTypesOptions[1]?.value,
    first_name: '',
    last_name: '',
    email: '',
    [countryCodeName]: 'PH',
    mobile: '',
    [projectCodeName]: '',
    description: '',
  })

  const handleInputChange = (event: any): void => {
    const { name, value } = event.target

    setFormData({
      ...formData,
      [name]: value,
    })
  }

  useEffect(() => {
    if (queryParams.get('form-success')) {
      setIsSuccessModal(true)
    }
  }, [queryParams])

  useEffect(() => {
    setIsCorporate(corporateRecordTypes.includes(formData?.recordType))
  }, [formData])

  console.log('isPROD', isProd)
  console.log('isQa', isQA)
  console.log('isMaster', isMaster)
  console.log('ActionUrl', ActionUrl)
  console.log('Site URL', process.env.GATSBY_SITE_URL)

  return (
    <>
      <form action={ActionUrl?.url} method="POST" className="sales-force-form">
        <HiddenFields />

        <div
          className={`${
            corporateRecordTypes.includes(formData?.recordType)
              ? ''
              : 'sales-force-form__recordType'
          } `}
        >
          <label htmlFor="recordType">Inquiry Type</label>
          <select
            id="recordType"
            name="recordType"
            title="Type"
            onChange={handleInputChange}
          >
            {recordTypesOptions.map(({ label, value }) => (
              <option
                selected={label === 'Individual'}
                key={value}
                value={value}
              >
                {label}
              </option>
            ))}
          </select>
        </div>

        {isCorporate ? (
          <div>
            <label htmlFor="company">Company</label>
            <input
              id="company"
              maxLength={40}
              name="company"
              size={20}
              type="text"
            />
          </div>
        ) : null}

        <div>
          <label htmlFor="first_name">First Name</label>
          <input
            id="first_name"
            maxLength={40}
            name="first_name"
            size={20}
            type="text"
            onChange={handleInputChange}
            value={formData.first_name}
          />
        </div>

        <div>
          <label htmlFor="last_name">Last Name</label>
          <input
            id="last_name"
            maxLength={80}
            name="last_name"
            size={20}
            type="text"
            onChange={handleInputChange}
            value={formData.last_name}
          />
        </div>

        <div>
          <label htmlFor="email">Email</label>
          <input
            id="email"
            maxLength={80}
            name="email"
            size={20}
            type="email"
            onChange={handleInputChange}
            value={formData.email}
          />
        </div>

        <div>
          <label htmlFor={countryCodeName}>Mobile Country Code:</label>
          <select
            id={countryCodeName}
            name={countryCodeName}
            title="Mobile Country Code"
            onChange={handleInputChange}
          >
            <option value="">--None--</option>
            {CountryCode.map(({ label, value }) => (
              <option
                selected={value === 'PH'}
                key={`${label}-${value}`}
                value={value}
              >
                {label}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label htmlFor="mobile">Mobile</label>
          <input
            id="mobile"
            maxLength={40}
            name="mobile"
            size={20}
            type="text"
            onChange={handleInputChange}
          />
        </div>

        <div>
          <label htmlFor={projectCodeName}>Project Inquired:</label>
          <select
            id={projectCodeName}
            name={projectCodeName}
            title="Project Inquired"
            onChange={handleInputChange}
          >
            <option value="">--None--</option>
            {PropertyCode.map(({ label, value }) => (
              <option key={`${label}-${value}`} value={value}>
                {label}
              </option>
            ))}
          </select>
        </div>

        <div className="sales-force-form__description">
          <label htmlFor="description">Description</label>
          <textarea name="description" onChange={handleInputChange} />
        </div>

        {/* Handle submit */}
        <div className="sales-force-form__handle-submit">
          <div className="sales-force-form__handle-submit-content">
            <div className="sales-force-form__handle-submit-content-text">
              <label htmlFor="i-agree">
                <input
                  id="i-agree"
                  type="checkbox"
                  checked={isAgreeChecked}
                  readOnly
                />
                <span
                  role="checkbox"
                  onClick={(event): void => {
                    event.currentTarget.blur()
                    setIsAgreeChecked(!isAgreeChecked)
                  }}
                  onKeyPress={(event): void => {
                    if (event.which === 13) {
                      setIsAgreeChecked(!isAgreeChecked)
                    }
                  }}
                  tabIndex={0}
                  aria-checked={isAgreeChecked}
                  aria-labelledby="i-agree"
                  data-testid="policy"
                />
              </label>
              <div className="sales-force-form__handle-submit-content-text-tc">
                I Agree to the{' '}
                <a href="/terms-and-conditions/" target="_blank">
                  Terms and Conditions
                </a>{' '}
                and{' '}
                <a href="/privacy-policy/" target="_blank">
                  Privacy Notice
                </a>{' '}
                of Amaia
              </div>
            </div>
            <ReCAPTCHA
              sitekey={process.env.GATSBY_RECAPTCHA_API_KEY || ''}
              onChange={(value: string | null): void => {
                setIsRecaptchaChecked(!!value)
              }}
            />

            <button
              type="submit"
              className="sales-force-form__handle-submit-content-btn"
              disabled={!isAgreeChecked || !isRecaptchaChecked}
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </>
  )
}

export default ContactUsFormSF
