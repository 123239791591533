/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/camelcase */
export const defaultFieldNames = {
  // Captcha Settings:
  //           For the ALISI brand, the captcha key is ALISI.
  //           For the Alveo brand, the captcha key is forAlveo.
  orgId: '',
  // Org ID (oid): This field changes depending on the environment (test, production, etc.).
  oid: '',
  // return URL after submitting form
  retURL: `${process.env.GATSBY_SITE_URL}`,
  // Brand value
  brand: '',
  // Campaign Code: Only needed if the form is for a specific campaign. If it’s a general form, leave this field blank.
  campaignCode: '',
  // Preferred Seller: Only needed if the form is for a specific seller, otherwise leave it blank.
  preferredSeller: '',
  // Digital Lead: Set to a fixed value of 1.
  digitalLead: '',
  // ALISI: 1  - Everything else other then ALISI 0
  otherThanAlisi: '',
}

export const devValues = {
  orgId: '00DIp0000000Ro1',
  oid: '00DIp0000000Ro1',
  retURL: `${
    process.env.GATSBY_SITE_URL || 'localhost:8000'
  }/contact-us/?form-success=true`,
  brand: '00NIp000000aveU',
  campaignCode: '00NIp000000ahLi',
  preferredSeller: '00NIp000000aqNi',
  digitalLead: '00NIp000000b0Kt',
  otherThanAlisi: '00NIp000000b0ML',
}

export const masterValues = {
  orgId: '00DIn000000LPfs',
  oid: '00DIn000000LPfs',
  retURL: `${process.env.GATSBY_SITE_URL}/contact-us/?form-success=true`,
  brand: '00NIn000000sBFt',
  campaignCode: '00NIn000000sBG3',
  preferredSeller: '00NIn000000sBGQ',
  digitalLead: '00NIn000000sBG9',
  otherThanAlisi: '00NIn000000sBFp',
}

export const qaValues = {
  orgId: '00DF8000000ElTI',
  oid: '00DF8000000ElTI',
  retURL: `${process.env.GATSBY_SITE_URL}contact-us/?form-success=true`,
  brand: '00NF800000AWrhd',
  campaignCode: '00NF800000AWrhp',
  preferredSeller: '00NF800000AWrhs',
  digitalLead: '00NF800000AWrhq',
  otherThanAlisi: '00NF800000AWrho',
}

export const prodValues = {
  orgId: '00D28000001Gnqh',
  oid: '00D28000001Gnqh',
  retURL: `${process.env.GATSBY_SITE_URL}/contact-us/`,
  brand: '00NIh000000mMim',
  campaignCode: '00NIh000000mMiy',
  preferredSeller: '00NIh000000mMj1',
  digitalLead: '00NIh000000mMiz',
  otherThanAlisi: '00NIh000000mMix',
}

export const isProd =
  process.env.GATSBY_SITE_URL &&
  process.env.GATSBY_SITE_URL.includes('www.amaialand.com')

export const isQA =
  process.env.GATSBY_SITE_URL &&
  process.env.GATSBY_SITE_URL.includes('qa.d28pjsz7i2iaom.amplifyapp')

export const isMaster =
  process.env.GATSBY_SITE_URL &&
  process.env.GATSBY_SITE_URL.includes('master.d329b6c01n7ufy.amplifyapp')

// ? To clean up
export const isLocalEnv =
  process.env.GATSBY_SITE_URL === 'http://localhost:8000' ||
  process.env.NODE_ENV === 'development'

export const ActionUrl = {
  url: isProd
    ? 'https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8&orgId=00D28000001Gnqh'
    : `https://test.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8&orgId=${
        isQA
          ? qaValues.orgId
          : isMaster
          ? masterValues.orgId
          : '00DIp0000000Ro1'
      }`,
}

export const countryCodeName = isProd
  ? '00N2x000008U6jN'
  : isQA
  ? '00NF800000AI5uv'
  : isMaster
  ? '00N2x000008U6jN'
  : '00N2x000008U6jN'

export const projectCodeName = '00N0K00000Jzwje'

export const currentDate = JSON.stringify(new Date().getTime())

export const recordTypeOptions = [
  {
    label: 'Corporate',
    value: '012Ip000000L4gI',
    env: 'dev',
  },
  {
    label: 'Individual',
    value: '012Ip000000L3dN',
    env: 'dev',
  },

  {
    label: 'Corporate',
    value: '012In000000TSJM',
    env: 'master',
  },
  {
    label: 'Individual',
    value: '012In000000TSJN',
    env: 'master',
  },

  {
    label: 'Corporate',
    value: '012F8000001PwVy',
    env: 'qa',
  },
  {
    label: 'Individual',
    value: '012F8000001PwVz',
    env: 'qa',
  },
  {
    label: 'Corporate',
    value: '012Ih000000PF9S',
    env: 'prod',
  },
  {
    label: 'Individual',
    value: '012Ih000000PF9T',
    env: 'prod',
  },
]

export const corporateRecordTypes = [
  '012Ip000000L4gI',
  '012In000000TSJM',
  '012F8000001PwVy',
  '012Ih000000PF9S',
]

export const CountryCode = [
  {
    label: 'US',
    value: 'US',
  },
  {
    label: 'AC',
    value: 'AC',
  },
  {
    label: 'AD',
    value: 'AD',
  },
  {
    label: 'AE',
    value: 'AE',
  },
  {
    label: 'AF',
    value: 'AF',
  },
  {
    label: 'AG',
    value: 'AG',
  },
  {
    label: 'AI',
    value: 'AI',
  },
  {
    label: 'AL',
    value: 'AL',
  },
  {
    label: 'AM',
    value: 'AM',
  },
  {
    label: 'AO',
    value: 'AO',
  },
  {
    label: 'AQ',
    value: 'AQ',
  },
  {
    label: 'AR',
    value: 'AR',
  },

  {
    label: 'AS',
    value: 'AS',
  },
  {
    label: 'AT',
    value: 'AT',
  },

  {
    label: 'AU',
    value: 'AU',
  },
  {
    label: 'AW',
    value: 'AW',
  },
  {
    label: 'AX',
    value: 'AX',
  },
  {
    label: 'AZ',
    value: 'AZ',
  },
  {
    label: 'BA',
    value: 'BA',
  },
  {
    label: 'BB',
    value: 'BB',
  },
  {
    label: 'BD',
    value: 'BD',
  },
  {
    label: 'BE',
    value: 'BE',
  },
  {
    label: 'BF',
    value: 'BF',
  },
  {
    label: 'BG',
    value: 'BG',
  },

  {
    label: 'BH',
    value: 'BH',
  },
  {
    label: 'BI',
    value: 'BI',
  },

  {
    label: 'BJ',
    value: 'BJ',
  },
  {
    label: 'BL',
    value: 'BL',
  },
  {
    label: 'BM',
    value: 'BM',
  },
  {
    label: 'BN',
    value: 'BN',
  },
  {
    label: 'BO',
    value: 'BO',
  },
  {
    label: 'BQ',
    value: 'BQ',
  },
  {
    label: 'BR',
    value: 'BR',
  },
  {
    label: 'BS',
    value: 'BS',
  },
  {
    label: 'BT',
    value: 'BT',
  },
  {
    label: 'BV',
    value: 'BV',
  },

  {
    label: 'BW',
    value: 'BW',
  },
  {
    label: 'BY',
    value: 'BY',
  },

  {
    label: 'BZ',
    value: 'BZ',
  },
  {
    label: 'CA',
    value: 'CA',
  },
  {
    label: 'CC',
    value: 'CC',
  },
  {
    label: 'CD',
    value: 'CD',
  },
  {
    label: 'CF',
    value: 'CF',
  },
  {
    label: 'CG',
    value: 'CG',
  },
  {
    label: 'CH',
    value: 'CH',
  },
  {
    label: 'CI',
    value: 'CI',
  },
  {
    label: 'CK',
    value: 'CK',
  },
  {
    label: 'CL',
    value: 'CL',
  },

  {
    label: 'CM',
    value: 'CM',
  },
  {
    label: 'CN',
    value: 'CN',
  },

  {
    label: 'CO',
    value: 'CO',
  },
  {
    label: 'CR',
    value: 'CR',
  },
  {
    label: 'CU',
    value: 'CU',
  },
  {
    label: 'CV',
    value: 'CV',
  },
  {
    label: 'CW',
    value: 'CW',
  },
  {
    label: 'CX',
    value: 'CX',
  },
  {
    label: 'CY',
    value: 'CY',
  },
  {
    label: 'CZ',
    value: 'CZ',
  },
  {
    label: 'DE',
    value: 'DE',
  },
  {
    label: 'DJ',
    value: 'DJ',
  },

  {
    label: 'DK',
    value: 'DK',
  },
  {
    label: 'DM',
    value: 'DM',
  },

  {
    label: 'DO',
    value: 'DO',
  },
  {
    label: 'DZ',
    value: 'DZ',
  },
  {
    label: 'EC',
    value: 'EC',
  },
  {
    label: 'EE',
    value: 'EE',
  },
  {
    label: 'EG',
    value: 'EG',
  },
  {
    label: 'ER',
    value: 'ER',
  },
  {
    label: 'ES',
    value: 'ES',
  },
  {
    label: 'ET',
    value: 'ET',
  },
  {
    label: 'FI',
    value: 'FI',
  },
  {
    label: 'FJ',
    value: 'FJ',
  },

  {
    label: 'FK',
    value: 'FK',
  },
  {
    label: 'FM',
    value: 'FM',
  },

  {
    label: 'FO',
    value: 'FO',
  },
  {
    label: 'FR',
    value: 'FR',
  },
  {
    label: 'GA',
    value: 'GA',
  },
  {
    label: 'GB',
    value: 'GB',
  },
  {
    label: 'GD',
    value: 'GD',
  },
  {
    label: 'GE',
    value: 'GE',
  },
  {
    label: 'GF',
    value: 'GF',
  },
  {
    label: 'GG',
    value: 'GG',
  },
  {
    label: 'GH',
    value: 'GH',
  },
  {
    label: 'GI',
    value: 'GI',
  },
  {
    label: 'GL',
    value: 'GL',
  },
  {
    label: 'GM',
    value: 'GM',
  },

  {
    label: 'GN',
    value: 'GN',
  },
  {
    label: 'GP',
    value: 'GP',
  },
  {
    label: 'GQ',
    value: 'GQ',
  },
  {
    label: 'GR',
    value: 'GR',
  },
  {
    label: 'GS',
    value: 'GS',
  },
  {
    label: 'GT',
    value: 'GT',
  },
  {
    label: 'GU',
    value: 'GU',
  },
  {
    label: 'GW',
    value: 'GW',
  },
  {
    label: 'GY',
    value: 'GY',
  },
  {
    label: 'HK',
    value: 'HK',
  },
  {
    label: 'HM',
    value: 'HM',
  },
  {
    label: 'HN',
    value: 'HN',
  },

  {
    label: 'HR',
    value: 'HR',
  },
  {
    label: 'HT',
    value: 'HT',
  },
  {
    label: 'HU',
    value: 'HU',
  },
  {
    label: 'ID',
    value: 'ID',
  },
  {
    label: 'IE',
    value: 'IE',
  },
  {
    label: 'IL',
    value: 'IL',
  },
  {
    label: 'IM',
    value: 'IM',
  },
  {
    label: 'IN',
    value: 'IN',
  },
  {
    label: 'IO',
    value: 'IO',
  },
  {
    label: 'IQ',
    value: 'IQ',
  },
  {
    label: 'IR',
    value: 'IR',
  },
  {
    label: 'IS',
    value: 'IS',
  },

  {
    label: 'IT',
    value: 'IT',
  },
  {
    label: 'JE',
    value: 'JE',
  },
  {
    label: 'JM',
    value: 'JM',
  },
  {
    label: 'JO',
    value: 'JO',
  },
  {
    label: 'JP',
    value: 'JP',
  },
  {
    label: 'KE',
    value: 'KE',
  },
  {
    label: 'KG',
    value: 'KG',
  },
  {
    label: 'KH',
    value: 'KH',
  },
  {
    label: 'KI',
    value: 'KI',
  },
  {
    label: 'KM',
    value: 'KM',
  },
  {
    label: 'KN',
    value: 'KN',
  },
  {
    label: 'KP',
    value: 'KP',
  },

  {
    label: 'KR',
    value: 'KR',
  },
  {
    label: 'KW',
    value: 'KW',
  },
  {
    label: 'KY',
    value: 'KY',
  },
  {
    label: 'KZ',
    value: 'KZ',
  },
  {
    label: 'LA',
    value: 'LA',
  },
  {
    label: 'LB',
    value: 'LB',
  },
  {
    label: 'LC',
    value: 'LC',
  },
  {
    label: 'LI',
    value: 'LI',
  },
  {
    label: 'LK',
    value: 'LK',
  },
  {
    label: 'LR',
    value: 'LR',
  },
  {
    label: 'LS',
    value: 'LS',
  },
  {
    label: 'LT',
    value: 'LT',
  },

  {
    label: 'LU',
    value: 'LU',
  },
  {
    label: 'LV',
    value: 'LV',
  },
  {
    label: 'LY',
    value: 'LY',
  },
  {
    label: 'MA',
    value: 'MA',
  },
  {
    label: 'MC',
    value: 'MC',
  },
  {
    label: 'MD',
    value: 'MD',
  },
  {
    label: 'ME',
    value: 'ME',
  },
  {
    label: 'MF',
    value: 'MF',
  },
  {
    label: 'MG',
    value: 'MG',
  },
  {
    label: 'MH',
    value: 'MH',
  },
  {
    label: 'MK',
    value: 'MK',
  },
  {
    label: 'ML',
    value: 'ML',
  },

  {
    label: 'MN',
    value: 'MN',
  },
  {
    label: 'MO',
    value: 'MO',
  },
  {
    label: 'MP',
    value: 'MP',
  },
  {
    label: 'MQ',
    value: 'MQ',
  },
  {
    label: 'MR',
    value: 'MR',
  },
  {
    label: 'MS',
    value: 'MS',
  },
  {
    label: 'MT',
    value: 'MT',
  },
  {
    label: 'MU',
    value: 'MU',
  },
  {
    label: 'MV',
    value: 'MV',
  },
  {
    label: 'MW',
    value: 'MW',
  },
  { label: 'MX', value: 'MX' },
  { label: 'MY', value: 'MY' },
  { label: 'MZ', value: 'MZ' },
  { label: 'NA', value: 'NA' },
  { label: 'NC', value: 'NC' },
  { label: 'NE', value: 'NE' },
  { label: 'NF', value: 'NF' },
  { label: 'NG', value: 'NG' },
  { label: 'NI', value: 'NI' },
  { label: 'NL', value: 'NL' },
  { label: 'NO', value: 'NO' },
  { label: 'NP', value: 'NP' },
  { label: 'NR', value: 'NR' },
  { label: 'NU', value: 'NU' },
  { label: 'NZ', value: 'NZ' },
  { label: 'OM', value: 'OM' },
  { label: 'PA', value: 'PA' },
  { label: 'PE', value: 'PE' },
  { label: 'PF', value: 'PF' },
  { label: 'PG', value: 'PG' },
  { label: 'PH', value: 'PH' },
  { label: 'PK', value: 'PK' },
  { label: 'PL', value: 'PL' },
  { label: 'PM', value: 'PM' },
  { label: 'PN', value: 'PN' },
  { label: 'PR', value: 'PR' },
  { label: 'PS', value: 'PS' },
  { label: 'PT', value: 'PT' },
  { label: 'PW', value: 'PW' },
  { label: 'PY', value: 'PY' },
  { label: 'QA', value: 'QA' },
  { label: 'RE', value: 'RE' },
  { label: 'RO', value: 'RO' },
  { label: 'RS', value: 'RS' },
  { label: 'RU', value: 'RU' },
  { label: 'RW', value: 'RW' },
  { label: 'SA', value: 'SA' },
  { label: 'SB', value: 'SB' },
  { label: 'SC', value: 'SC' },
  { label: 'SD', value: 'SD' },
  { label: 'SE', value: 'SE' },
  { label: 'SG', value: 'SG' },
  { label: 'SH', value: 'SH' },
  { label: 'SI', value: 'SI' },
  { label: 'SJ', value: 'SJ' },
  { label: 'SK', value: 'SK' },
  { label: 'SL', value: 'SL' },
  { label: 'SM', value: 'SM' },
  { label: 'SN', value: 'SN' },
  { label: 'SO', value: 'SO' },
  { label: 'SR', value: 'SR' },
  { label: 'SS', value: 'SS' },
  { label: 'ST', value: 'ST' },
  { label: 'SV', value: 'SV' },
  { label: 'SX', value: 'SX' },
  { label: 'SY', value: 'SY' },
  { label: 'SZ', value: 'SZ' },
  { label: 'TA', value: 'TA' },
  { label: 'TC', value: 'TC' },
  { label: 'TD', value: 'TD' },
  { label: 'TG', value: 'TG' },
  { label: 'TH', value: 'TH' },
  { label: 'TJ', value: 'TJ' },
  { label: 'TK', value: 'TK' },
  { label: 'TL', value: 'TL' },
  { label: 'TM', value: 'TM' },
  { label: 'TN', value: 'TN' },
  { label: 'TO', value: 'TO' },
  { label: 'TR', value: 'TR' },
  { label: 'TT', value: 'TT' },
  { label: 'TV', value: 'TV' },
  { label: 'TW', value: 'TW' },
  { label: 'TZ', value: 'TZ' },
  { label: 'UA', value: 'UA' },
  { label: 'UG', value: 'UG' },
  { label: 'UY', value: 'UY' },
  { label: 'UZ', value: 'UZ' },
  { label: 'VA', value: 'VA' },
  { label: 'VC', value: 'VC' },
  { label: 'VE', value: 'VE' },
  { label: 'VG', value: 'VG' },
  { label: 'VI', value: 'VI' },
  { label: 'VN', value: 'VN' },
  { label: 'VU', value: 'VU' },
  { label: 'WF', value: 'WF' },
  { label: 'WS', value: 'WS' },
  { label: 'YE', value: 'YE' },
  { label: 'YT', value: 'YT' },
  { label: 'ZA', value: 'ZA' },
  { label: 'ZM', value: 'ZM' },
  { label: 'ZW', value: 'ZW' },
]

export const PropertyCode = [
  { label: 'Amaia Scapes Bauan', value: 'Amaia Scapes Bauan' },
  { label: 'Amaia Scapes Bulacan', value: 'Amaia Scapes Bulacan' },
  { label: 'Amaia Scapes Cabanatuan', value: 'Amaia Scapes Cabanatuan' },
  { label: 'Amaia Scapes Cabuyao', value: 'Amaia Scapes Cabuyao' },
  {
    label: 'Amaia Scapes Cagayan De Oro',
    value: 'Amaia Scapes Cagayan De Oro',
  },
  { label: 'Amaia Scapes Capas', value: 'Amaia Scapes Capas' },
  { label: 'Amaia Scapes General Trias', value: 'Amaia Scapes General Trias' },
  { label: 'Amaia Scapes Iloilo', value: 'Amaia Scapes Iloilo' },
  { label: 'Amaia Scapes Laguna', value: 'Amaia Scapes Laguna' },
  { label: 'Amaia Scapes Lipa', value: 'Amaia Scapes Lipa' },
  { label: 'Amaia Scapes Lucena', value: 'Amaia Scapes Lucena' },
  { label: 'Amaia Scapes North Point', value: 'Amaia Scapes North Point' },
  { label: 'Amaia Scapes Pampanga', value: 'Amaia Scapes Pampanga' },
  { label: 'Amaia Scapes Rizal', value: 'Amaia Scapes Rizal' },
  { label: 'Amaia Scapes San Fernando', value: 'Amaia Scapes San Fernando' },
  { label: 'Amaia Scapes San Pablo', value: 'Amaia Scapes San Pablo' },
  { label: 'Amaia Scapes Trece', value: 'Amaia Scapes Trece' },
  {
    label: 'Amaia Scapes Trece Martires',
    value: 'Amaia Scapes Trece Martires',
  },
  { label: 'Amaia Scapes Urdaneta', value: 'Amaia Scapes Urdaneta' },
  { label: 'Amaia Series Novaliches', value: 'Amaia Series Novaliches' },
  { label: 'Amaia Series Nuvali', value: 'Amaia Series Nuvali' },
  { label: 'Amaia Series Vermosa', value: 'Amaia Series Vermosa' },
  { label: 'Amaia Skies Avenida', value: 'Amaia Skies Avenida' },
  { label: 'Amaia Skies Cubao', value: 'Amaia Skies Cubao' },
  { label: 'Amaia Skies Shaw', value: 'Amaia Skies Shaw' },
  { label: 'Amaia Skies Sta. Mesa', value: 'Amaia Skies Sta. Mesa' },
  { label: 'Amaia Square Novaliches', value: 'Amaia Square Novaliches' },
  { label: 'Amaia Steps Alabang', value: 'Amaia Steps Alabang' },
  { label: 'Amaia Steps Altaraza', value: 'Amaia Steps Altaraza' },
  { label: 'Amaia Steps Bicutan', value: 'Amaia Steps Bicutan' },
  { label: 'Amaia Steps Mandaue', value: 'Amaia Steps Mandaue' },
  { label: 'Amaia Steps Novaliches', value: 'Amaia Steps Novaliches' },
  { label: 'Amaia Steps Nuvali', value: 'Amaia Steps Nuvali' },
  { label: 'Amaia Steps Parkway Nuvali', value: 'Amaia Steps Parkway Nuvali' },
  { label: 'Amaia Steps Pasig', value: 'Amaia Steps Pasig' },
  { label: 'Amaia Steps Sucat', value: 'Amaia Steps Sucat' },
  {
    label: 'Amaia Steps The Junction Place',
    value: 'Amaia Steps The Junction Place',
  },
  {
    label: 'Amaia Steps Two Capitol Central',
    value: 'Amaia Steps Two Capitol Central',
  },
  { label: 'Scapes Batangas', value: 'Scapes Batangas' },
  { label: 'Scapes Cam Sur', value: 'Scapes Cam Sur' },
  { label: 'Scapes Cavite', value: 'Scapes Cavite' },
  { label: 'Scapes Gen. Trias', value: 'Scapes Gen. Trias' },
  { label: 'Series Novaliches', value: 'Series Novaliches' },
  { label: 'Steps Capitol Central', value: 'Steps Capitol Central' },
]
